import { useState } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react';

import { getIndexAngle, getValuesByKey } from './utils/array.utils'

const THIS_OPTIONS = [
  { thing: 'Bitcoin', plural: false },
  { thing: 'Cows', plural: true },
  { thing: 'Cars', plural: true },
  { thing: 'Blenders', plural: true },
  { thing: 'Private Jets', plural: true },
  { thing: 'Central Bankers', plural: true },
  { thing: 'The US Dollar', plural: false },
  { thing: 'The Military', plural: false },
  { thing: 'Christmas Lights', plural: true },
  { thing: 'Laundry Machines', plural: true },
  { thing: 'Gold', plural: false },
  { thing: 'Mainstream Media', plural: false },
  { thing: 'Grocery Stores', plural: false },
  { thing: 'Big Pharma', plural: false },
  { thing: 'Financial Advisors', plural: true },
  { thing: 'The WEF', plural: false },
  { thing: 'Lawyers', plural: true },
  { thing: 'Wind Turbines', plural: true },
  { thing: 'Greenpeace', plural: false },
  { thing: 'Newspapers', plural: true },
  { thing: 'Video Games', plural: true },
  { thing: 'Space X', plural: false },
  { thing: 'Artificial Intelligence', plural: false },
  { thing: 'Vibrators', plural: true },
  { thing: 'Kettles', plural: true },
]

const MORE_OPTIONS = [
  'Energy',
  'Sugar',
  'Oxygen',
  'Oil',
  'Corn',
  'CO2',
  'Lubricant',
  'Silicon',
  'Bullets',
  'Electricity',
  'Copper',
  'Microchips',
  'Plastic',
  'Grass',
  'Guns',
  'Chemicals',
  'Paper',
  'Water',
  'Concrete',
  'Disk Space',
  'Pollution',
]

const THAT_OPTIONS = [
  'Small Countries',
  'Antarctica',
  'Norway',
  'Mozambique',
  'The Auto Industry',
  'Sex Workers',
  'Africa',
  'Nepal',
  'The Internet',
  'Vegans',
  'Argentina',
  'Liberals',
  'Finland',
  'Slovenia',
  'Farmers',
  'North Korea',
  'China',
  'Doctors',
  'The United States',
  'The Police',
  'Albania',
  'Robots',
]

function App() {
  const [thisThing, setThisThing] = useState('Bitcoin')
  const [isPlural, setIsPlural] = useState(false)
  const [thisSelectVisible, setThisSelectVisible] = useState(false)
  const [more, setMore] = useState('Energy')
  const [moreSelectVisible, setMoreSelectVisible] = useState(false)
  const [that, setThat] = useState('Small Countries')
  const [thatSelectVisible, setThatSelectVisible] = useState(false)
  const [gradientAngle, setGradientAngle] = useState(180)
  const [color1, setColor1] = useState(275)
  const [color2, setColor2] = useState(0)
  const [color3, setColor3] = useState(35)

  const bgGradient = `
    linear-gradient(${gradientAngle}deg,
    hsl(${color3}, 100%, 42%) 0%,
    hsl(${color2}, 100%, 31%) 50%,
    hsl(${color1}, 100%, 40%) 100%)
  `

  const handleThisChange = (x) => {
    const { thing, plural } = JSON.parse(x)
    const thingValues = getValuesByKey(THIS_OPTIONS, 'thing')
    const thingDegrees = getIndexAngle(thingValues, thing)

    setIsPlural(plural)
    setThisThing(thing)
    setThisSelectVisible(false)
    setGradientAngle(thingDegrees)
    setColor1(thingDegrees)
  }
  const handleShowThisSelect = () => {
    setThisSelectVisible(true)
  }

  const handleMoreChange = (x) => {
    const morePercentage = getIndexAngle(MORE_OPTIONS, x)
    setMore(x)
    setMoreSelectVisible(false)
    setColor2(morePercentage)
  }
  const handleShowMoreSelect = () => {
    setMoreSelectVisible(true)
  }

  const handleThatChange = (x) => {
    const thatPercentage = getIndexAngle(THAT_OPTIONS, x)
    setThat(x)
    setThatSelectVisible(false)
    setColor3(thatPercentage)
  }
  const handleShowThatSelect = () => {
    setThatSelectVisible(true)
  }


  const boxStyles = {
    border: '2px solid rgba(255, 255, 255, 0.25)',
    borderRadius: '8px',
    position: 'relative',
    padding: '8px',
    maxW: '480px',
    w: '100%',
    cursor: 'pointer',
    transition: 'all 0.35s ease',
    _hover: {
      borderColor: 'white',
    },
  }

  const selectStyles = {
    position: 'fixed',
    direction: 'column',
    justify: 'flex-start',
    align: 'center',
    zIndex: '999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    py: '12px',
    bg: 'black',
    color: 'white',
    overflow: 'auto',
  }
  const optionStyles = {
    width: '100%',
    padding: '8px',
    justify: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'all 0.35s ease',
    _hover: {
      color: 'rgba(253,29,29,1)',
    },
  }
  const textStyles = {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
    m: '12px 0',
  }
  const staticTextStyles = {
    ...textStyles,
    m: '24px 0',
  }

  return (
    <Flex
      w="100vw"
      h="100vh"
      justify="center"
      align="center"
      bg={bgGradient}
    >
      <Flex
        direction="column"
        gap="12px"
        justify="center"
        align="center"
        p="24px"
        w="100%"
      >
        <Flex
          display={thisSelectVisible ? 'flex' : 'none'}
          {...selectStyles}
        >
          {THIS_OPTIONS
            .filter((x) => x.thing !== thisThing)
            .map((item) => {
              const { thing } = item
              return (
                <Flex
                  onClick={() => handleThisChange(JSON.stringify(item))}
                  key={thing}
                  {...optionStyles}
                >
                  {thing}
                </Flex>
            )}
          )}
        </Flex>
        <Box
          onClick={handleShowThisSelect}
          {...boxStyles}
        >
          <Text {...textStyles}>
            {thisThing}
          </Text>
        </Box>


        <Text {...staticTextStyles}>
          {isPlural ? 'USE MORE' : 'USES MORE'}
        </Text>
        

        <Flex
          display={moreSelectVisible ? 'flex' : 'none'}
          {...selectStyles}
        >
          {MORE_OPTIONS
            .filter((x) => x !== more)
            .map((item) => {
              return (
                <Flex
                  onClick={() => handleMoreChange(item)}
                  key={item}
                  {...optionStyles}
                >
                  {item}
                </Flex>
              )
          })}
        </Flex>

        <Box
          onClick={handleShowMoreSelect}
          {...boxStyles}
        >
          <Text {...textStyles}>
            {more}
          </Text>
        </Box>


        <Text {...staticTextStyles}>
          THAN
        </Text>


        <Flex
          display={thatSelectVisible ? 'flex' : 'none'}
          {...selectStyles}
        >
          {THAT_OPTIONS
            .filter((x) => x !== that)
            .map((item) => {
              return (
                <Flex
                  onClick={() => handleThatChange(item)}
                  key={item}
                  {...optionStyles}
                >
                  {item}
                </Flex>
              )
          })}
        </Flex>

        <Box
          onClick={handleShowThatSelect}
          {...boxStyles}
        >
          <Text {...textStyles}>
            {that}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}

export default App;

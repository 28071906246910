export const getIndexPercentage = (arr, value) => {
    const index = arr.indexOf(value);
    if (index === -1) {
      return false;
    } else {
      const percentage = (index / arr.length) * 100;
      return percentage;
    }
  };

export const getIndexAngle = (arr, value) => {
    const index = arr.indexOf(value);
    if (index === -1) {
      return false;
    } else {
      const angle = (index / arr.length) * 360;
      return angle;
    }
  };

export const getValuesByKey = (arr, key) => {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        const value = obj[key];
        result.push(value);
    }
    return result;
};
  